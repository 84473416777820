<template>
  <div class="resource-content" v-if="!!resourceData.resourceType">
    <transition name="fade" mode="out-in">
      <template v-if="resourceData.resourceType === resourceEnum.TEACHING_RESOURCE_TYPE">
        <transition name="fade" mode="out-in">
          <div v-if="resourceData.teachingType === teachingTypeEnum.PPT">
            <transition name="fade" mode="out-in">
              <iframe
                v-if="Array.isArray(resourceData.contents) && resourceData.contents[0] && !!resourceData.contents[0].url"
                :src="correctURL('file', resourceData.contents[0].url)"
                frameborder="0"
                style="width: 100%; height: calc(100vh - 30px);"
              ></iframe>
            </transition>
          </div>
          <template
            v-else-if="resourceData.teachingType === teachingTypeEnum.VIDEO"
          >
            <transition name="fade" mode="out-in">
              <div v-if="!audioUrl" class="aliplayer-wrap">
                <VideoPlayer
                  :ref="handleVideoPlayerCompRef"
                  controlsShow
                />
              </div>
              <div v-else class="audio-wrap">
                <audio :src="correctURL('video', audioUrl)" controls></audio>
              </div>
            </transition>
          </template>
          <div v-else-if="resourceData.teachingType === teachingTypeEnum.IMG">
            <div class="pic-wrap">
              <transition name="fade" mode="out-in">
                <img :src="correctURL('file', currentPicUrl)" :key="currentPicUrl" />
              </transition>
            </div>
          </div>
        </transition>
      </template>
      <div v-else-if="resourceData.resourceType === resourceEnum.EXERCISES_RESOURCE_TYPE">
        <ExerciseItem
          v-for="(item, index) in resourceData.contents"
          :key="index"
          :exercise-index="index"
          :exercise-data="item"
        />
      </div>
    </transition>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, watch, computed, nextTick } from 'vue';

  import PageLoading from '@/components/PageLoading/index.vue';
  import ExerciseItem from './ExerciseItem.vue';
  import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue';

  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import { teachingTypeEnum } from '@/enumerators/teaching-types-map.js';

  import { correctURL } from '@/utils/url.js';

  const props = defineProps({
    resourceData: {
      type: Object,
      default: () => {}
    }
  });

  const loading = ref(false);

  const videoPlayerRef = ref(null);
  const audioUrl = ref('');

  let videoPlayerCompRefCb = null;
  function handleVideoPlayerCompRef(el) {
    videoPlayerRef.value = el;
    if (videoPlayerCompRefCb) {
      videoPlayerCompRefCb();
      videoPlayerCompRefCb = null;
    }
  }

  watch(() => props.resourceData, value => {
    audioUrl.value = '';
    if (
      value.resourceType === resourceEnum.TEACHING_RESOURCE_TYPE &&
      value.teachingType === teachingTypeEnum.VIDEO
    ) {
      const mediaData = value.contents[0];
      switch (mediaData.ext) {
        case '.mp3':
          audioUrl.value = mediaData.url;
          break;
        default:
          if (videoPlayerRef.value) {
            // mediaData.playSource ?
              // videoPlayerRef.value.initEncryptedVideo(mediaData.playSource) :
              videoPlayerRef.value.initVideo(mediaData.videoId);
          } else {
            videoPlayerCompRefCb = () => {
              // mediaData.playSource ?
                // videoPlayerRef.value.initEncryptedVideo(mediaData.playSource) :
                videoPlayerRef.value.initVideo(mediaData.videoId);
            };
          }
      }
    }
  }, { immediate: true });

  const currentPicIndex = ref(0);
  const currentPicUrl = computed(() => {
    if (
      props.resourceData &&
      props.resourceData.resourceType === resourceEnum.TEACHING_RESOURCE_TYPE &&
      props.resourceData.teachingType === teachingTypeEnum.IMG
    ) {
      return props.resourceData.contents[currentPicIndex.value].url;
    } else {
      return '';
    }
  });

  defineExpose({
    prePic: () => { currentPicIndex.value--; },
    nextPic: () => { currentPicIndex.value++; },
    resetData: () => {
      currentPicIndex.value = 0;
    }
  });
</script>

<style lang="less" scoped>
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-to,
  .fade-leave-from {
    opacity: 1;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .6s ease;
  }

  .resource-content {
    position: relative;
    z-index: 1;
    min-height: 320px;
  }

  .aliplayer-wrap {
    height: calc(100vh - 30px);
  }

  .audio-wrap {
    padding: 0 16px 12px;
  }

  .pic-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 320px;

    img {
      max-width: 100%;
    }
  }
</style>